import { getLocList,patentCountry,listSimilarProductV2, search, detail, caseList, caseHome, caseInfo,listSimilarProductV2ByPic,queryAbstractImage  } from '@/api/intellectual'
import { getToken } from '@/utils/auth'

const state = {
    token: getToken()
}
const actions = {
    getLocList({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            getLocList(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    patentCountry({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            patentCountry(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    queryAbstractImage({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            queryAbstractImage(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    listSimilarProductV2ByPic({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            listSimilarProductV2ByPic(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //诉讼详情
    caseInfo({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            caseInfo(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //诉讼列表
    caseList({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            caseList(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //首页轮播图诉讼
    caseHome({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            caseHome(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    //专利详情
    detail({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            detail(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    // 阿里图片搜索
    listSimilarProductV2({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            listSimilarProductV2(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    //专利号片搜索
    search({ commit, state }, form) {
        return new Promise((resolve, reject) => {
            search(form, state.token)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    actions
}
